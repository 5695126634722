.Dash {
  height: fit-content;
  width: 100%;
  /*max-width: 90vw;*/
  flex-direction: row;
  justify-content: space-around;
  display: flex;
  position: relative;
  fill: transparent;
}

.Dash-header-grid {
  display: grid;
  width: 100%;
  grid-template-columns: 47% 47%;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.Dash-header {
  justify-items: left;
  display: flex;
}

.Dash-buttonBox {
  padding-top: 2vh;
  width: 100%;
  /*max-width: 90vw;*/
  align-items: center;
  max-height: fit-content;
  grid-template-columns: repeat(auto-fill, 215px);
  justify-content: center;
  display: grid;
  fill: transparent;
}

.Dash-buttonBox-header {
  padding-top: 2vh;
  width: 100%;
  /*max-width: 90vw;*/
  align-items: center;
  max-height: fit-content;
  grid-template-columns: 215px 215px;
  justify-content: center;
  display: grid;
  fill: transparent;
  margin-bottom: 10px;
}

.Dash-toggle-box {
  color: #333333;
  background-color: #ffffff00;
  font-size: 14px;
  width: fit-content;
  height: fit-content;
  border-width: 0px;
  justify-content: center;
  display: flex;
  text-decoration: none;
  font-weight: bold;
}

.Dash-toggle-box:hover {
  cursor: pointer;
}

.Dash-delete-form-popUp-window {
  width: 100%;
  height: 100%;
  z-index: 5;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;
  display: flex;
}

.Dash-delete-form-popUp-Box {
  width: 630px;
  max-width: 60vw;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  border-radius: 5px;
  position: fixed;
  top: 50%;
  border: 5px solid var(--primaryColor);
  background-color: var(--backColor);
}

.Dash-toggle {
  color: var(--primaryColor);
  background-color: var(--backColor);
  font-size: medium;
  justify-content: space-between;
  align-content: center;
  display: flex;
  border-radius: 20px;
  border: 2px solid var(--primaryColor);
  width: 110px;
  height: 30px;
}

.Dash-toggle-ball {
  color: var(--primaryColor);
  background-color: var(--primaryColor);
  font-size: medium;
  align-items: center;
  border-radius: 30px;
  border: 0px solid var(--backColor);
  margin: 5px;
  width: 24px;
  height: 20px;
}

.Dash-toggle-active {
  color: var(--backColor);
  background-color: var(--primaryColor);
  font-size: medium;
  justify-content: space-between;
  align-content: center;
  display: flex;
  border-radius: 20px;
  border: 2px solid var(--primaryColor);
  width: 110px;
  height: 30px;
}

.Dash-toggle-ball-active {
  color: var(--primaryColor);
  background-color: var(--backColor);
  font-size: medium;
  align-items: center;
  border-radius: 30px;
  border: 0px solid var(--primaryColor);
  margin: 5px;
  width: 24px;
  height: 20px;
}

.Dash-toggle-text {
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-weight: normal;
}

.Dash-menu {
  color: #333333;
  padding: 5px 20px;
  background-color: #ffffff00;
  font-size: 14px;
  border-radius: 20px;
  width: fit-content;
  height: fit-content;
  margin: 10px 10px;
  border-width: 0px;
  text-decoration: none;
  font-weight: bold;
}

.Dash-menu:hover {
  color: var(--primaryColor);
  cursor: pointer;
}

.Dash-menu-onPage {
  color: var(--primaryColor);
  padding: 5px 20px;
  background-color: #ffffff00;
  font-size: 14px;
  border-radius: 20px;
  width: fit-content;
  height: fit-content;
  margin: 10px 10px;
  border-width: 0px;
  text-decoration: none;
  font-weight: bold;
}

.Dash-menu-onPage-header {
  color: var(--backColor);
  padding: 5px 20px;
  background-color: #ffffff00;
  font-size: 14px;
  border-radius: 20px;
  width: fit-content;
  height: fit-content;
  margin: 10px 10px;
  border-width: 0px;
  text-decoration: none;
  font-weight: bold;
}

.Dash-menu-header {
  color: #333333;
  padding: 5px 20px;
  background-color: #ffffff00;
  font-size: 14px;
  border-radius: 20px;
  width: fit-content;
  height: fit-content;
  margin: 10px 10px;
  border-width: 0px;
  text-decoration: none;
  font-weight: bold;
}

.Dash-menu-header:hover {
  color: var(--backColor);
  cursor: pointer;
}

.Dash-Box {
  width: 100%;
  /*max-width: 90vw;*/
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
}

.Dash-Box-Focus {
  width: calc(100% - 22px);
  /*max-width: 90vw;*/
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  border-radius: 5px;
  border: 1px solid #333333;
}



.Dash-Grid-titulo {
  width: 100%;
  /*max-width: 90vw;*/
  display: grid;
  grid-template-columns: calc(100% - 115px) 115px;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.Dash-Group {
  width: 100%;
  /*max-width: 90vw;*/
  display: grid;
  grid-template-columns: 84% 14%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.Dash-Group-Quest {
  width: 100%;
  /*max-width: 90vw;*/
  display: grid;
  grid-template-columns: 72% 26%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.Dash-Group-all {
  width: 100%;
  /*max-width: 90vw;*/
  display: grid;
  grid-template-columns: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.Dash-Group-Grid {
  width: 100%;
  /*max-width: 90vw;*/
  display: grid;
  grid-template-columns: 47% 47%;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.Dash-Group-5 {
  width: 100%;
  /*max-width: 90vw;*/
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  padding-bottom: 50px;
  padding-top: 10px;
}

.Dash-Group-Select {
  width: 80px;
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  justify-items: center;
  column-gap: 30px;
  padding-right: 0px;
  padding-left: 0px;
}

.Dash-Group-Item {
  width: 100%;
  /*max-width: 90vw;*/
  padding-bottom: 5px;
  padding-top: 5px;
  font-weight: normal;
  text-align: left;
  display: flex;
}

.Dash-Group-Item-Metodo {
  width: 100%;
  /*max-width: 90vw;*/
  padding-bottom: 5px;
  padding-top: 5px;
  font-weight: bold;
  text-align: left;
  display: flex;
}

.Dash-Group-Item-Metodo-op {
  width: 100%;
  /*max-width: 90vw;*/
  height: 100%;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 30px;
  font-weight: normal;
  justify-content: left;
  display: flex;
}

.Dash-separador {
  width: 100%;
  border-bottom: 1px solid #838383;
  height: 20px;
  margin-bottom: 10px;
}

.Dash-Quest {
  width: 100%;
  /*max-width: 90vw;*/
  width: calc(100% - 20px);
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 20px;
  text-align: left;
  display: flex;
}

.Dash-Group-options {
  width: 100%;
  /*max-width: 90vw;*/
  padding: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-weight: normal;
  display: flex;
}

.Dash-Tabel {
  width: 100%;
}

.Dash-Button {
  height: 100%;
}

.Dash-TextBox {
  width: 100%;
  /*max-width: 90vw;*/
  background-color: var(--backColor);
  border-radius: 5px;
  border: 1px solid #333333;
  width: 100%;
  min-height: 30px;
  margin: 0px 0px;
  padding: 5px;
  overflow-y: auto;
  resize: none;
  outline: none;
}

.Dash-Titulo {
  width: 100%;
  /*max-width: 90vw;*/
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: black;
  background-color: var(--backColor);
  font-size: x-large;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #333333;
  height: 40px;
  outline: none;
}

.Dash-Box-Titulo {
  width: 100%;
  /*max-width: 90vw;*/
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  border-radius: 5px;
  border: 0px solid #333333;
}

.Dash-Name {
  width: 100%;
  color: black;
  background-color: var(--backColor);
  font-size: medium;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #333333;
  height: 30px;
  outline: none;
}

.Dash-Name-Inv {
  width: 100%;
  color: black;
  background-color: var(--backColor);
  font-size: medium;
  align-items: center;
  border-radius: 5px;
  border: 0px solid #333333;
  height: 30px;
  outline: none;
}

.Dash-Select-Sign {
  width: 50%;
  color: black;
  background-color: var(--backColor);
  font-size: medium;
  align-items: center;
  border-radius: 5px;
  border: 0px solid #333333;
  height: 30px;
  outline: none;
  align-self: center;
  justify-content: center;
  display: flex;
}

.Dash-Range-label {
  margin-top: 25px;
  width: 100%;
  /*max-width: 90vw;*/
  display: grid;
  grid-template-columns: 15% 70% 15%;
  align-items: flex-end;
  justify-content: space-around;
}

.Dash-Range-label-text {
  width: 100%;
  /*max-width: 90vw;*/
  padding-bottom: 3px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  display: flex;
}

.Dash-Range {
  width: 100%;
  text-align: center;
  margin-bottom: 2%;
}

.Dash-Button-Add {
  width: calc(100% - 22px);
  /*max-width: 90vw;*/
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  font-size: xx-large;
  color: #6d6d6d;
  border-radius: 5px;
  border: 1px solid #333333;
}

.Dash-Button-Add:hover {
  background-color: rgba(134, 134, 134, 0.333);
  cursor: pointer;
}

.Dash-Range-Valeu {
  background-color: var(--primaryColor);
  color: var(--backColor);
  font-size: medium;
  align-items: center;
  text-align: center;
  padding-top: 3px;
  border-radius: 5px;
  border: 2px solid var(--primaryColor);
  width: 50px;
  height: 35px;
}

.Dash-numerico {
  width: 100%;
  /*max-width: 90vw;*/
  padding: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-weight: normal;
  display: flex;
  margin-top: 7px;
}

.Dash-imageQuest {
  position: relative;
  width: fit-content;
  margin-top: 10px;
}

.Dash-Homunculu {
  width: 600px;
  max-width: 90vw;
  z-index: 1;
}

input.Dash-imageQuest1 {
  position: absolute;
  left: calc(40.4% - 11px);
  transform: scale(1.2);
  top: calc(8% - 12px);
}

input.Dash-imageQuest2 {
  position: absolute;
  left: calc(59.1% - 12px);
  transform: scale(1.2);
  top: calc(8% - 12px);
}

input.Dash-imageQuest3 {
  position: absolute;
  left: calc(49.8% - 12px);
  transform: scale(1.2);
  top: calc(16.3% - 12px);
}

input.Dash-imageQuest4 {
  position: absolute;
  left: calc(36.2% - 12px);
  transform: scale(1.2);
  top: calc(17.5% - 12px);
}

input.Dash-imageQuest5 {
  position: absolute;
  left: calc(63.2% - 12px);
  transform: scale(1.2);
  top: calc(17.5% - 12px);
}

input.Dash-imageQuest6 {
  position: absolute;
  left: calc(43.3% - 12px);
  transform: scale(1.2);
  top: calc(20.4% - 12px);
}

input.Dash-imageQuest7 {
  position: absolute;
  left: calc(56.2% - 12px);
  transform: scale(1.2);
  top: calc(20.4% - 12px);
}

input.Dash-imageQuest8 {
  position: absolute;
  left: calc(25.3% - 12px);
  transform: scale(1.2);
  top: calc(20.5% - 12px);
}

input.Dash-imageQuest9 {
  position: absolute;
  left: calc(74.2% - 12px);
  transform: scale(1.2);
  top: calc(20.5% - 12px);
}

input.Dash-imageQuest10 {
  position: absolute;
  left: calc(49.8% - 12px);
  transform: scale(1.2);
  top: calc(25.7% - 12px);
}

input.Dash-imageQuest11 {
  position: absolute;
  left: calc(16.3% - 12px);
  transform: scale(1.2);
  top: calc(33.3% - 12px);
}

input.Dash-imageQuest12 {
  position: absolute;
  left: calc(83.4% - 12px);
  transform: scale(1.2);
  top: calc(33.3% - 12px);
}

input.Dash-imageQuest13 {
  position: absolute;
  left: calc(49.8% - 12px);
  transform: scale(1.2);
  top: calc(41.5% - 12px);
}

input.Dash-imageQuest14 {
  position: absolute;
  left: calc(41.5% - 12px);
  transform: scale(1.2);
  top: calc(42.5% - 12px);
}

input.Dash-imageQuest15 {
  position: absolute;
  left: calc(58.2% - 12px);
  transform: scale(1.2);
  top: calc(42.5% - 12px);
}

input.Dash-imageQuest16 {
  position: absolute;
  left: calc(20.8% - 12px);
  transform: scale(1.2);
  top: calc(46% - 12px);
}

input.Dash-imageQuest17 {
  position: absolute;
  left: calc(79.2% - 12px);
  transform: scale(1.2);
  top: calc(46% - 12px);
}

input.Dash-imageQuest18 {
  position: absolute;
  left: calc(32.5% - 12px);
  transform: scale(1.2);
  top: calc(49.2% - 12px);
}

input.Dash-imageQuest19 {
  position: absolute;
  left: calc(67.4% - 12px);
  transform: scale(1.2);
  top: calc(49.2% - 12px);
}

input.Dash-imageQuest20 {
  position: absolute;
  left: calc(8.5% - 12px);
  transform: scale(1.2);
  top: calc(51.2% - 12px);
}

input.Dash-imageQuest21 {
  position: absolute;
  left: calc(91.4% - 12px);
  transform: scale(1.2);
  top: calc(51.2% - 12px);
}

input.Dash-imageQuest22 {
  position: absolute;
  left: calc(12% - 12px);
  transform: scale(1.2);
  top: calc(54.1% - 12px);
}

input.Dash-imageQuest23 {
  position: absolute;
  left: calc(17.3% - 12px);
  transform: scale(1.2);
  top: calc(54.1% - 12px);
}

input.Dash-imageQuest24 {
  position: absolute;
  left: calc(22.5% - 12px);
  transform: scale(1.2);
  top: calc(54.1% - 12px);
}

input.Dash-imageQuest25 {
  position: absolute;
  left: calc(27.9% - 12px);
  transform: scale(1.2);
  top: calc(54.1% - 12px);
}

input.Dash-imageQuest26 {
  position: absolute;
  left: calc(72% - 12px);
  transform: scale(1.2);
  top: calc(54.1% - 12px);
}

input.Dash-imageQuest27 {
  position: absolute;
  left: calc(77.4% - 12px);
  transform: scale(1.2);
  top: calc(54.1% - 12px);
}

input.Dash-imageQuest28 {
  position: absolute;
  left: calc(82.6% - 12px);
  transform: scale(1.2);
  top: calc(54.1% - 12px);
}

input.Dash-imageQuest29 {
  position: absolute;
  left: calc(87.9% - 12px);
  transform: scale(1.2);
  top: calc(54.1% - 12px);
}

input.Dash-imageQuest30 {
  position: absolute;
  left: calc(0% - 2px);
  transform: scale(1.2);
  top: calc(54.6% - 12px);
}

input.Dash-imageQuest31 {
  position: absolute;
  left: calc(98% - 12px);
  transform: scale(1.2);
  top: calc(54.6% - 12px);
}

input.Dash-imageQuest32 {
  position: absolute;
  left: calc(12% - 12px);
  transform: scale(1.2);
  top: calc(56.8% - 12px);
}

input.Dash-imageQuest33 {
  position: absolute;
  left: calc(17.3% - 12px);
  transform: scale(1.2);
  top: calc(56.8% - 12px);
}

input.Dash-imageQuest34 {
  position: absolute;
  left: calc(22.5% - 12px);
  transform: scale(1.2);
  top: calc(56.8% - 12px);
}

input.Dash-imageQuest35 {
  position: absolute;
  left: calc(27.9% - 12px);
  transform: scale(1.2);
  top: calc(56.8% - 12px);
}

input.Dash-imageQuest36 {
  position: absolute;
  left: calc(72% - 12px);
  transform: scale(1.2);
  top: calc(56.8% - 12px);
}

input.Dash-imageQuest37 {
  position: absolute;
  left: calc(77.4% - 12px);
  transform: scale(1.2);
  top: calc(56.8% - 12px);
}

input.Dash-imageQuest38 {
  position: absolute;
  left: calc(82.6% - 12px);
  transform: scale(1.2);
  top: calc(56.8% - 12px);
}

input.Dash-imageQuest39 {
  position: absolute;
  left: calc(87.9% - 12px);
  transform: scale(1.2);
  top: calc(56.8% - 12px);
}

input.Dash-imageQuest40 {
  position: absolute;
  left: calc(12% - 12px);
  transform: scale(1.2);
  top: calc(59.5% - 12px);
}

input.Dash-imageQuest41 {
  position: absolute;
  left: calc(17.3% - 12px);
  transform: scale(1.2);
  top: calc(59.5% - 12px);
}

input.Dash-imageQuest42 {
  position: absolute;
  left: calc(22.5% - 12px);
  transform: scale(1.2);
  top: calc(59.5% - 12px);
}

input.Dash-imageQuest43 {
  position: absolute;
  left: calc(27.9% - 12px);
  transform: scale(1.2);
  top: calc(59.5% - 12px);
}

input.Dash-imageQuest44 {
  position: absolute;
  left: calc(72% - 12px);
  transform: scale(1.2);
  top: calc(59.5% - 12px);
}

input.Dash-imageQuest45 {
  position: absolute;
  left: calc(77.4% - 12px);
  transform: scale(1.2);
  top: calc(59.5% - 12px);
}

input.Dash-imageQuest46 {
  position: absolute;
  left: calc(82.6% - 12px);
  transform: scale(1.2);
  top: calc(59.5% - 12px);
}

input.Dash-imageQuest47 {
  position: absolute;
  left: calc(87.9% - 12px);
  transform: scale(1.2);
  top: calc(59.5% - 12px);
}

input.Dash-imageQuest48 {
  position: absolute;
  left: calc(32.5% - 12px);
  transform: scale(1.2);
  top: calc(65.7% - 12px);
}

input.Dash-imageQuest49 {
  position: absolute;
  left: calc(67.4% - 12px);
  transform: scale(1.2);
  top: calc(65.7% - 12px);
}

input.Dash-imageQuest50 {
  position: absolute;
  left: calc(32.2% - 12px);
  transform: scale(1.2);
  top: calc(78.9% - 12px);
}

input.Dash-imageQuest51 {
  position: absolute;
  left: calc(67.7% - 12px);
  transform: scale(1.2);
  top: calc(78.9% - 12px);
}

input.Dash-imageQuest52 {
  position: absolute;
  left: calc(32.2% - 12px);
  transform: scale(1.2);
  top: calc(81.5% - 12px);
}

input.Dash-imageQuest53 {
  position: absolute;
  left: calc(67.7% - 12px);
  transform: scale(1.2);
  top: calc(81.5% - 12px);
}

input.Dash-imageQuest54 {
  position: absolute;
  left: calc(32.2% - 12px);
  transform: scale(1.2);
  top: calc(85.7% - 12px);
}

input.Dash-imageQuest55 {
  position: absolute;
  left: calc(67.7% - 12px);
  transform: scale(1.2);
  top: calc(85.7% - 12px);
}

input.Dash-imageQuest56 {
  position: absolute;
  left: calc(38.4% - 12px);
  transform: scale(1.2);
  top: calc(87.9% - 12px);
}

input.Dash-imageQuest57 {
  position: absolute;
  left: calc(61.7% - 12px);
  transform: scale(1.2);
  top: calc(87.9% - 12px);
}

input.Dash-imageQuest58 {
  position: absolute;
  left: calc(44.4% - 12px);
  transform: scale(1.2);
  top: calc(91.3% - 12px);
}

input.Dash-imageQuest59 {
  position: absolute;
  left: calc(55.7% - 12px);
  transform: scale(1.2);
  top: calc(91.3% - 12px);
}

input.Dash-imageQuest60 {
  position: absolute;
  left: calc(24.4% - 12px);
  transform: scale(1.2);
  top: calc(94% - 12px);
}

input.Dash-imageQuest61 {
  position: absolute;
  left: calc(29.7% - 12px);
  transform: scale(1.2);
  top: calc(94% - 12px);
}

input.Dash-imageQuest62 {
  position: absolute;
  left: calc(35% - 12px);
  transform: scale(1.2);
  top: calc(94% - 12px);
}

input.Dash-imageQuest63 {
  position: absolute;
  left: calc(40.3% - 12px);
  transform: scale(1.2);
  top: calc(94% - 12px);
}

input.Dash-imageQuest64 {
  position: absolute;
  left: calc(59.8% - 12px);
  transform: scale(1.2);
  top: calc(94% - 12px);
}

input.Dash-imageQuest65 {
  position: absolute;
  left: calc(65.1% - 12px);
  transform: scale(1.2);
  top: calc(94% - 12px);
}

input.Dash-imageQuest66 {
  position: absolute;
  left: calc(70.4% - 12px);
  transform: scale(1.2);
  top: calc(94% - 12px);
}

input.Dash-imageQuest67 {
  position: absolute;
  left: calc(75.7% - 12px);
  transform: scale(1.2);
  top: calc(94% - 12px);
}

input.Dash-imageQuest68 {
  position: absolute;
  left: calc(24.4% - 12px);
  transform: scale(1.2);
  top: calc(96.8% - 12px);
}

input.Dash-imageQuest69 {
  position: absolute;
  left: calc(29.7% - 12px);
  transform: scale(1.2);
  top: calc(96.8% - 12px);
}

input.Dash-imageQuest70 {
  position: absolute;
  left: calc(35% - 12px);
  transform: scale(1.2);
  top: calc(96.8% - 12px);
}

input.Dash-imageQuest71 {
  position: absolute;
  left: calc(40.3% - 12px);
  transform: scale(1.2);
  top: calc(96.8% - 12px);
}

input.Dash-imageQuest72 {
  position: absolute;
  left: calc(59.8% - 12px);
  transform: scale(1.2);
  top: calc(96.8% - 12px);
}

input.Dash-imageQuest73 {
  position: absolute;
  left: calc(65.1% - 12px);
  transform: scale(1.2);
  top: calc(96.8% - 12px);
}

input.Dash-imageQuest74 {
  position: absolute;
  left: calc(70.4% - 12px);
  transform: scale(1.2);
  top: calc(96.8% - 12px);
}

input.Dash-imageQuest75 {
  position: absolute;
  left: calc(75.7% - 12px);
  transform: scale(1.2);
  top: calc(96.8% - 12px);
}

@media screen and (max-width: 800px) {
  .Dash-Grid-titulo {
    grid-template-columns: 100%;
  }
}

@media screen and (max-width: 400px) {
  .Dash-Group-Item {
    font-size: calc(2.5vw);
  }

  .Dash-Group-Item-Metodo {
    font-size: calc(2.5vw);
  }

  .Dash-Group-Item-Metodo-op {
    font-size: calc(5px + 2vw);
  }

  .Dash-Group-options {
    font-size: calc(2.5vw);
  }

  .Dash-TextBox {
    font-size: calc(2.5vw);
  }

  .Dash-Range-label-text {
    font-size: calc(2.5vw);
  }
}

@media screen and (max-width: 520px) and (min-width: 401px) {
  .Dash-Group-Item {
    font-size: calc(5vw - 10px);
  }

  .Dash-Group-Item-Metodo {
    font-size: calc(5vw - 10px);
  }

  .Dash-Group-Item-Metodo-op {
    font-size: calc(5px + 2vw);
  }

  .Dash-Group-options {
    font-size: calc(5vw - 10px);
  }

  .Dash-TextBox {
    font-size: calc(5vw - 10px);
  }

  .Dash-Range-label-text {
    font-size: calc(5vw - 10px);
  }
}
