:root {
  --primaryColor: #61dafb;
  --backColor: white;
  --desableColor: #838282;
}

/*--primaryColor: #0075ff;*/
.App {
  flex-direction: column;
  text-align: center;
  overflow-x: hidden;
  overflow-y: auto;
  display: flex;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: var(--backColor);
  height: 15vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: var(--primaryColor);
  box-sizing: border-box;
  border-bottom: 0px rgba(0, 0, 0, 0.514);
  box-shadow: 1px 0px 3px #3333332a;
  position: fixed;
  z-index: 10;
}

.App-subheader {
  background-color: var(--primaryColor);
  color: var(--backColor);
  height: 7.5vh;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
  display: flex;
}

.App-subheader-text {
  padding-left: 10vw;
  margin-left: 4px;
  font-size: calc(10px + 2vmin);
  font-weight: 500;
  padding-top: 0;
  padding-bottom: 0;
}

.App-text {
  white-space: pre-wrap;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.App-body {
  background-color: var(--backColor);
  flex-direction: column;
  display: flex;
  min-height: 83vh;
  max-width: 100vw;
  justify-content: space-between;
  padding-top: 15vh;
  align-items: center;
  z-index: 5;
}

.App-buttonBox {
  padding-top: 2vh;
  max-height: fit-content;
  flex-direction: row;
  justify-content: center;
  display: flex;
  fill: transparent;
}

.App-subbuttonbox {
  max-height: fit-content;
}

.App-formBox {
  height: fit-content;
  max-width: 100vw;
  flex-direction: row;
  justify-content: center;
  display: flex;
  fill: transparent;
}

.App-frame {
  min-height: 40.5vw;
  max-height: 50.625%;
  min-width: 66.6vw;
  max-width: 90%;
  justify-content: center;
  display: flex;
  padding-bottom: 5%;
}

.App-form {
  max-width: 810px;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 10vw;
  display: flex;
}

.App-form-image {
  max-width: 90vw;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.App-link {
  color: var(--primaryColor);
}

.App-button {
  color: var(--primaryColor);
  background-color: var(--backColor);
  font-size: medium;
  align-items: center;
  border-radius: 5px;
  border: 2px solid var(--primaryColor);
  width: 200px;
  height: 35px;
  margin: 10px 10px;
}

.App-button-onPage {
  background-color: var(--primaryColor);
  color: var(--backColor);
  font-size: medium;
  align-items: center;
  border-radius: 5px;
  border: 2px solid var(--primaryColor);
  width: 200px;
  height: 35px;
  margin: 10px 10px;
}

.App-button:enabled:hover {
  background-color: var(--primaryColor);
  color: var(--backColor);
  cursor: pointer;
}

.App-button:disabled {
  background-color: var(--desableColor);
  border: 2px solid var(--desableColor);
  color: var(--backColor);
}

.App-Fild {
  color: black;
  background-color: var(--backColor);
  font-size: medium;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #333333;
  width: 200px;
  height: 30px;
  margin: 10px 10px;
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media screen and (max-width: 900px) {
  .App-buttonBox {
    flex-direction: column;
  }

  .App-form {
    max-width: 90vw;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
