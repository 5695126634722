.Form {
  height: fit-content;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  display: flex;
  fill: transparent;
}

.Form-buttonBox {
  padding-top: 2vh;
  width: 100%;
  max-width: 90vw;
  align-items: center;
  max-height: fit-content;
  grid-template-columns: repeat(auto-fill, 215px);
  justify-content: center;
  display: grid;
  fill: transparent;
}

.Form-Box {
  width: 100%;
  max-width: 90vw;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
}

.Form-Box-Focus {
  width: 100%;
  max-width: 90vw;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  border-radius: 5px;
  border: 0px solid #333333;
}

.Form-Group {
  max-width: 90vw;
  display: grid;
  width: calc(100% - 5px);
  margin-left: 5px;
  grid-template-columns: 84% 14%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.Form-Group-Quest {
  max-width: 90vw;
  display: grid;
  width: 100%;
  grid-template-columns: 72% 26%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.Form-Group-all {
  max-width: 90vw;
  display: grid;
  width: 100%;
  grid-template-columns: 100%;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.Form-Group-Grid {
  max-width: 90vw;
  display: grid;
  width: 100%;
  grid-template-columns: 47% 47%;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  padding-bottom: 10px;
}

.Form-Group-5 {
  max-width: 90vw;
  display: grid;
  width: 100%;
  grid-template-columns: auto auto auto auto auto auto;
  justify-content: space-between;
  justify-items: center;
  align-items: center;
  padding-bottom: 50px;
  padding-top: 10px;
}

.Form-Group-Select {
  max-width: 90vw;
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-items: center;
  column-gap: 30px;
  padding-right: 0px;
  padding-left: 15px;
}

.Form-Group-Item {
  max-width: 90vw;
  width: 100%;
  padding-bottom: 5px;
  padding-top: 5px;
  font-weight: normal;
  text-align: left;
  display: flex;
}

.Form-Quest {
  max-width: 90vw;
  width: calc(100% - 20px);
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 20px;
  text-align: left;
  display: flex;
}

.Form-Group-options {
  max-width: 90vw;
  padding: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-weight: normal;
  display: flex;
}

.Form-Tabel {
  width: 100%;
}

.Form-Button {
  height: 100%;
}

.Form-TextBox {
  max-width: 90vw;
  background-color: var(--backColor);
  border-radius: 5px;
  border: 1px solid #333333;
  width: 100%;
  min-height: 30px;
  margin: 0px 0px;
  padding: 5px;
  overflow-y: auto;
  resize: none;
  outline: none;
}

.Form-Titulo {
  width: 100%;
  max-width: 90vw;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  color: black;
  background-color: var(--backColor);
  font-size: x-large;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #333333;
  height: 40px;
  outline: none;
}

.Form-Box-Titulo {
  width: 100%;
  max-width: 90vw;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  border-radius: 5px;
  border: 0px solid #333333;
}

.Form-Name {
  width: calc(100% - 20px);
  color: black;
  background-color: var(--backColor);
  font-size: medium;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #333333;
  height: 30px;
  outline: none;
}

.Form-Range-label {
  margin-top: 0px;
  max-width: 90vw;
  display: grid;
  width: 100%;
  grid-template-columns: 15% 70% 15%;
  align-items: flex-end;
  justify-content: space-around;
}

.Form-Range-label-text {
  max-width: 90vw;
  padding-bottom: 3px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  display: flex;
}

.Form-Range {
  width: 100%;
  text-align: center;
  margin-bottom: 0;
}

.Form-Button-Add {
  width: 100%;
  max-width: 90vw;
  flex-direction: column;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  display: flex;
  font-size: xx-large;
  color: #6d6d6d;
  border-radius: 5px;
  border: 1px solid #333333;
}

.Form-Button-Add:hover {
  background-color: rgb(245, 245, 245);
  cursor: pointer;
}

.Form-Range-Valeu {
  background-color: var(--primaryColor);
  color: var(--backColor);
  font-size: medium;
  align-items: center;
  text-align: center;
  padding-top: 3px;
  border-radius: 5px;
  border: 2px solid var(--primaryColor);
  width: 50px;
  height: 35px;
}

.Form-numerico {
  max-width: 90vw;
  padding: 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-weight: normal;
  display: flex;
  margin-top: 7px;
}

.Forms-frame-box {
  height: 36vw;
  width: 64vw;
  justify-content: center;
  display: flex;
}

.Form-imageQuest {
  position: relative;
  width: fit-content;
  margin-top: 10px;
}

.Form-Homunculu {
  width: 600px;
  max-width: 90vw;
  z-index: 1;
}

input.Form-imageQuest1 {
  position: absolute;
  left: calc(40.4% - 11px);
  transform: scale(1.2);
  top: calc(8% - 12px);
}

input.Form-imageQuest2 {
  position: absolute;
  left: calc(59.1% - 12px);
  transform: scale(1.2);
  top: calc(8% - 12px);
}

input.Form-imageQuest3 {
  position: absolute;
  left: calc(49.8% - 12px);
  transform: scale(1.2);
  top: calc(16.3% - 12px);
}

input.Form-imageQuest4 {
  position: absolute;
  left: calc(36.2% - 12px);
  transform: scale(1.2);
  top: calc(17.5% - 12px);
}

input.Form-imageQuest5 {
  position: absolute;
  left: calc(63.2% - 12px);
  transform: scale(1.2);
  top: calc(17.5% - 12px);
}

input.Form-imageQuest6 {
  position: absolute;
  left: calc(43.3% - 12px);
  transform: scale(1.2);
  top: calc(20.4% - 12px);
}

input.Form-imageQuest7 {
  position: absolute;
  left: calc(56.2% - 12px);
  transform: scale(1.2);
  top: calc(20.4% - 12px);
}

input.Form-imageQuest8 {
  position: absolute;
  left: calc(25.3% - 12px);
  transform: scale(1.2);
  top: calc(20.5% - 12px);
}

input.Form-imageQuest9 {
  position: absolute;
  left: calc(74.2% - 12px);
  transform: scale(1.2);
  top: calc(20.5% - 12px);
}

input.Form-imageQuest10 {
  position: absolute;
  left: calc(49.8% - 12px);
  transform: scale(1.2);
  top: calc(25.7% - 12px);
}

input.Form-imageQuest11 {
  position: absolute;
  left: calc(16.3% - 12px);
  transform: scale(1.2);
  top: calc(33.3% - 12px);
}

input.Form-imageQuest12 {
  position: absolute;
  left: calc(83.4% - 12px);
  transform: scale(1.2);
  top: calc(33.3% - 12px);
}

input.Form-imageQuest13 {
  position: absolute;
  left: calc(49.8% - 12px);
  transform: scale(1.2);
  top: calc(41.5% - 12px);
}

input.Form-imageQuest14 {
  position: absolute;
  left: calc(41.5% - 12px);
  transform: scale(1.2);
  top: calc(42.5% - 12px);
}

input.Form-imageQuest15 {
  position: absolute;
  left: calc(58.2% - 12px);
  transform: scale(1.2);
  top: calc(42.5% - 12px);
}

input.Form-imageQuest16 {
  position: absolute;
  left: calc(20.8% - 12px);
  transform: scale(1.2);
  top: calc(46% - 12px);
}

input.Form-imageQuest17 {
  position: absolute;
  left: calc(79.2% - 12px);
  transform: scale(1.2);
  top: calc(46% - 12px);
}

input.Form-imageQuest18 {
  position: absolute;
  left: calc(32.5% - 12px);
  transform: scale(1.2);
  top: calc(49.2% - 12px);
}

input.Form-imageQuest19 {
  position: absolute;
  left: calc(67.4% - 12px);
  transform: scale(1.2);
  top: calc(49.2% - 12px);
}

input.Form-imageQuest20 {
  position: absolute;
  left: calc(8.5% - 12px);
  transform: scale(1.2);
  top: calc(51.2% - 12px);
}

input.Form-imageQuest21 {
  position: absolute;
  left: calc(91.4% - 12px);
  transform: scale(1.2);
  top: calc(51.2% - 12px);
}

input.Form-imageQuest22 {
  position: absolute;
  left: calc(12% - 12px);
  transform: scale(1.2);
  top: calc(54.1% - 12px);
}

input.Form-imageQuest23 {
  position: absolute;
  left: calc(17.3% - 12px);
  transform: scale(1.2);
  top: calc(54.1% - 12px);
}

input.Form-imageQuest24 {
  position: absolute;
  left: calc(22.5% - 12px);
  transform: scale(1.2);
  top: calc(54.1% - 12px);
}

input.Form-imageQuest25 {
  position: absolute;
  left: calc(27.9% - 12px);
  transform: scale(1.2);
  top: calc(54.1% - 12px);
}

input.Form-imageQuest26 {
  position: absolute;
  left: calc(72% - 12px);
  transform: scale(1.2);
  top: calc(54.1% - 12px);
}

input.Form-imageQuest27 {
  position: absolute;
  left: calc(77.4% - 12px);
  transform: scale(1.2);
  top: calc(54.1% - 12px);
}

input.Form-imageQuest28 {
  position: absolute;
  left: calc(82.6% - 12px);
  transform: scale(1.2);
  top: calc(54.1% - 12px);
}

input.Form-imageQuest29 {
  position: absolute;
  left: calc(87.9% - 12px);
  transform: scale(1.2);
  top: calc(54.1% - 12px);
}

input.Form-imageQuest30 {
  position: absolute;
  left: calc(0% - 2px);
  transform: scale(1.2);
  top: calc(54.6% - 12px);
}

input.Form-imageQuest31 {
  position: absolute;
  left: calc(98% - 12px);
  transform: scale(1.2);
  top: calc(54.6% - 12px);
}

input.Form-imageQuest32 {
  position: absolute;
  left: calc(12% - 12px);
  transform: scale(1.2);
  top: calc(56.8% - 12px);
}

input.Form-imageQuest33 {
  position: absolute;
  left: calc(17.3% - 12px);
  transform: scale(1.2);
  top: calc(56.8% - 12px);
}

input.Form-imageQuest34 {
  position: absolute;
  left: calc(22.5% - 12px);
  transform: scale(1.2);
  top: calc(56.8% - 12px);
}

input.Form-imageQuest35 {
  position: absolute;
  left: calc(27.9% - 12px);
  transform: scale(1.2);
  top: calc(56.8% - 12px);
}

input.Form-imageQuest36 {
  position: absolute;
  left: calc(72% - 12px);
  transform: scale(1.2);
  top: calc(56.8% - 12px);
}

input.Form-imageQuest37 {
  position: absolute;
  left: calc(77.4% - 12px);
  transform: scale(1.2);
  top: calc(56.8% - 12px);
}

input.Form-imageQuest38 {
  position: absolute;
  left: calc(82.6% - 12px);
  transform: scale(1.2);
  top: calc(56.8% - 12px);
}

input.Form-imageQuest39 {
  position: absolute;
  left: calc(87.9% - 12px);
  transform: scale(1.2);
  top: calc(56.8% - 12px);
}

input.Form-imageQuest40 {
  position: absolute;
  left: calc(12% - 12px);
  transform: scale(1.2);
  top: calc(59.5% - 12px);
}

input.Form-imageQuest41 {
  position: absolute;
  left: calc(17.3% - 12px);
  transform: scale(1.2);
  top: calc(59.5% - 12px);
}

input.Form-imageQuest42 {
  position: absolute;
  left: calc(22.5% - 12px);
  transform: scale(1.2);
  top: calc(59.5% - 12px);
}

input.Form-imageQuest43 {
  position: absolute;
  left: calc(27.9% - 12px);
  transform: scale(1.2);
  top: calc(59.5% - 12px);
}

input.Form-imageQuest44 {
  position: absolute;
  left: calc(72% - 12px);
  transform: scale(1.2);
  top: calc(59.5% - 12px);
}

input.Form-imageQuest45 {
  position: absolute;
  left: calc(77.4% - 12px);
  transform: scale(1.2);
  top: calc(59.5% - 12px);
}

input.Form-imageQuest46 {
  position: absolute;
  left: calc(82.6% - 12px);
  transform: scale(1.2);
  top: calc(59.5% - 12px);
}

input.Form-imageQuest47 {
  position: absolute;
  left: calc(87.9% - 12px);
  transform: scale(1.2);
  top: calc(59.5% - 12px);
}

input.Form-imageQuest48 {
  position: absolute;
  left: calc(32.5% - 12px);
  transform: scale(1.2);
  top: calc(65.7% - 12px);
}

input.Form-imageQuest49 {
  position: absolute;
  left: calc(67.4% - 12px);
  transform: scale(1.2);
  top: calc(65.7% - 12px);
}

input.Form-imageQuest50 {
  position: absolute;
  left: calc(32.2% - 12px);
  transform: scale(1.2);
  top: calc(78.9% - 12px);
}

input.Form-imageQuest51 {
  position: absolute;
  left: calc(67.7% - 12px);
  transform: scale(1.2);
  top: calc(78.9% - 12px);
}

input.Form-imageQuest52 {
  position: absolute;
  left: calc(32.2% - 12px);
  transform: scale(1.2);
  top: calc(81.5% - 12px);
}

input.Form-imageQuest53 {
  position: absolute;
  left: calc(67.7% - 12px);
  transform: scale(1.2);
  top: calc(81.5% - 12px);
}

input.Form-imageQuest54 {
  position: absolute;
  left: calc(32.2% - 12px);
  transform: scale(1.2);
  top: calc(85.7% - 12px);
}

input.Form-imageQuest55 {
  position: absolute;
  left: calc(67.7% - 12px);
  transform: scale(1.2);
  top: calc(85.7% - 12px);
}

input.Form-imageQuest56 {
  position: absolute;
  left: calc(38.4% - 12px);
  transform: scale(1.2);
  top: calc(87.9% - 12px);
}

input.Form-imageQuest57 {
  position: absolute;
  left: calc(61.7% - 12px);
  transform: scale(1.2);
  top: calc(87.9% - 12px);
}

input.Form-imageQuest58 {
  position: absolute;
  left: calc(44.4% - 12px);
  transform: scale(1.2);
  top: calc(91.3% - 12px);
}

input.Form-imageQuest59 {
  position: absolute;
  left: calc(55.7% - 12px);
  transform: scale(1.2);
  top: calc(91.3% - 12px);
}

input.Form-imageQuest60 {
  position: absolute;
  left: calc(24.4% - 12px);
  transform: scale(1.2);
  top: calc(94% - 12px);
}

input.Form-imageQuest61 {
  position: absolute;
  left: calc(29.7% - 12px);
  transform: scale(1.2);
  top: calc(94% - 12px);
}

input.Form-imageQuest62 {
  position: absolute;
  left: calc(35% - 12px);
  transform: scale(1.2);
  top: calc(94% - 12px);
}

input.Form-imageQuest63 {
  position: absolute;
  left: calc(40.3% - 12px);
  transform: scale(1.2);
  top: calc(94% - 12px);
}

input.Form-imageQuest64 {
  position: absolute;
  left: calc(59.8% - 12px);
  transform: scale(1.2);
  top: calc(94% - 12px);
}

input.Form-imageQuest65 {
  position: absolute;
  left: calc(65.1% - 12px);
  transform: scale(1.2);
  top: calc(94% - 12px);
}

input.Form-imageQuest66 {
  position: absolute;
  left: calc(70.4% - 12px);
  transform: scale(1.2);
  top: calc(94% - 12px);
}

input.Form-imageQuest67 {
  position: absolute;
  left: calc(75.7% - 12px);
  transform: scale(1.2);
  top: calc(94% - 12px);
}

input.Form-imageQuest68 {
  position: absolute;
  left: calc(24.4% - 12px);
  transform: scale(1.2);
  top: calc(96.8% - 12px);
}

input.Form-imageQuest69 {
  position: absolute;
  left: calc(29.7% - 12px);
  transform: scale(1.2);
  top: calc(96.8% - 12px);
}

input.Form-imageQuest70 {
  position: absolute;
  left: calc(35% - 12px);
  transform: scale(1.2);
  top: calc(96.8% - 12px);
}

input.Form-imageQuest71 {
  position: absolute;
  left: calc(40.3% - 12px);
  transform: scale(1.2);
  top: calc(96.8% - 12px);
}

input.Form-imageQuest72 {
  position: absolute;
  left: calc(59.8% - 12px);
  transform: scale(1.2);
  top: calc(96.8% - 12px);
}

input.Form-imageQuest73 {
  position: absolute;
  left: calc(65.1% - 12px);
  transform: scale(1.2);
  top: calc(96.8% - 12px);
}

input.Form-imageQuest74 {
  position: absolute;
  left: calc(70.4% - 12px);
  transform: scale(1.2);
  top: calc(96.8% - 12px);
}

input.Form-imageQuest75 {
  position: absolute;
  left: calc(75.7% - 12px);
  transform: scale(1.2);
  top: calc(96.8% - 12px);
}

@media screen and (max-width: 700px) and (min-width: 501px) {
  .Form-Group-Item {
    font-size: calc(5px + 2vw);
  }

  .Form-Group-options {
    font-size: calc(5px + 2vw);
  }

  .Form-TextBox {
    font-size: calc(5px + 2vw);
  }

  .Form-Range-label-text {
    font-size: calc(5px + 2vw);
  }
}

@media screen and (max-width: 400px) {
  .Form-Group-Item {
    font-size: calc(2.5vw);
  }

  .Form-Group-options {
    font-size: calc(2.5vw);
  }

  .Form-TextBox {
    font-size: calc(2.5vw);
  }

  .Form-Range-label-text {
    font-size: calc(2.5vw);
  }
}

@media screen and (max-width: 500px) and (min-width: 401px) {
  .Form-Group-Item {
    font-size: calc(5vw - 10px);
  }

  .Form-Group-options {
    font-size: calc(5vw - 10px);
  }

  .Form-TextBox {
    font-size: calc(5vw - 10px);
  }

  .Form-Range-label-text {
    font-size: calc(5vw - 10px);
  }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


/* NEW CF */


fieldset {
  border: 1px solid silver !important;
  outline: none !important;
}

.Mui-focused fieldset {
  border: 1px solid grey !important;
  outline: none !important;
}

.MuiOutlinedInput-input {
  background:  #fff !important;
}

.MuiButton-containedPrimary {
  color:  #fff !important;
}

.shadow {
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12) !important;
}

.custom-header {
  width: 100%;
  padding: 10px;
  background-color: #00a9d2 !important;
  color: #fff !important;
  font-weight: bold
}

.filter-menu .MuiPopover-paper {
  padding: 10px;
}

.filter-menu .MuiOutlinedInput-adornedStart {
  padding-left: 8px
}

.custom-header-section {
  background-color: #f2f2f2 !important;
  width: 100%;
  padding: 5px;
  box-shadow: none !important;
  margin-bottom: 10px;
}

.custom-alert-section {
  background-color: #fdeded !important;
  color: #5f2120;
  padding: 20px;
  margin-bottom: 10px;
}

.custom-success-section {
  background-color: #edf7ed !important;
  color: #1e4620;
  padding: 20px;
  margin-bottom: 10px;
}